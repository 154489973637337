import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import { Link } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import DocsHelper from 'util/DocsHelper';
import Routes from 'routing/Routes';

import { Button, Row, Col } from 'components/graylog';
import { DocumentTitle, ExternalLinkButton, PageHeader, Spinner, IfPermitted } from 'components/common';

import SystemJob from 'components/systemjobs/SystemJob';

import ArchiveActions from 'archive/ArchiveActions';
import ArchiveStore from 'archive/ArchiveStore';
import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveCatalog from 'archive/components/ArchiveCatalog';
import ArchiveConfig from 'archive/components/ArchiveConfig';
import ArchiveCreationForm from 'archive/components/ArchiveCreationForm';
import ArchiveLicenseStatus from 'archive/components/ArchiveLicenseStatus';

import CombinedProvider from 'injection/CombinedProvider';

const { IndicesStore, IndicesActions } = CombinedProvider.get('Indices');
const { SystemJobsStore, SystemJobsActions } = CombinedProvider.get('SystemJobs');

const ArchivePage = createReactClass({
  displayName: 'ArchivePage',

  mixins: [
    Reflux.connect(ArchiveStore),
    Reflux.connect(ArchiveLicenseStore, 'licenseStatus'),
    Reflux.listenTo(SystemJobsStore, 'onSystemJobs'),
    Reflux.listenTo(IndicesStore, 'onIndices'),
  ],

  getInitialState() {
    return {
      createJobs: [],
      restoreJobs: [],
      rebuildJobs: [],
    };
  },

  componentDidMount() {
    ArchiveLicenseActions.getLicenseStatus();
    ArchiveActions.searchPaginated(this.state.catalog.pagination.page, this.state.catalog.pagination.per_page, null);
    IndicesActions.listAll();
    SystemJobsActions.list();

    this.shortTimerId = setInterval(() => {
      SystemJobsActions.list();
    }, this.shortTimerInterval);

    this.timerId = setInterval(() => {
      ArchiveActions.searchPaginated(this.state.catalog.pagination.page, this.state.catalog.pagination.per_page, this.state.catalog.pagination.query);
      IndicesActions.listAll();
    }, this.timerInterval);
  },

  componentWillUnmount() {
    clearInterval(this.timerId);
    clearInterval(this.shortTimerId);
    this.timerId = undefined;
    this.shortTimerId = undefined;
  },

  onSystemJobs(data) {
    if (!data || !data.jobs) {
      return;
    }

    const createJobs = [];
    const restoreJobs = [];
    const rebuildJobs = [];

    Object.keys(data.jobs).forEach((nodeId) => {
      data.jobs[nodeId].jobs.forEach((job) => {
        switch (job.name) {
          case 'org.graylog.plugins.archive.job.ArchiveCreateSystemJob':
            createJobs.push(job);
            break;
          case 'org.graylog.plugins.archive.job.ArchiveRestoreSystemJob':
            restoreJobs.push(job);
            break;
          case 'org.graylog.plugins.archive.job.BuildCatalogSystemJob':
            rebuildJobs.push(job);
            break;
          default:
          // Ignore non-archive jobs.
        }
      });
    });

    this.setState({ rebuildJobs: rebuildJobs });

    let pagination = this.state.catalog && this.state.catalog.pagination ? this.state.catalog.pagination : undefined;
    if (!pagination) {
      pagination = { page: 1, per_page: ArchiveCatalog.DEFAULT_PAGE_SIZE, query: null };
    }
    if (restoreJobs.length > 0) {
      this.setState({ restoreJobs: restoreJobs });
    } else {
      // Check if there were jobs before. If so, list archives again to get the latest state.
      if (this.state.restoreJobs.length > 0) {
        ArchiveActions.searchPaginated(pagination.page,
          pagination.per_page,
          pagination.query);
      }
      this.setState({ restoreJobs: [] });
    }

    if (createJobs.length > 0) {
      this.setState({ createJobs: createJobs });
    } else {
      // Check if there were jobs before. If so, list archives again to get the latest state.
      if (this.state.createJobs.length > 0) {
        ArchiveActions.searchPaginated(pagination.page,
          pagination.per_page,
          pagination.query);
      }
      this.setState({ createJobs: [] });
    }
  },

  onIndices(data) {
    if (!data.indices) {
      return;
    }

    const openIndices = data.indices;

    const indices = Object.keys(openIndices).reduce((obj, name) => {
      const update = obj;

      update[name] = {
        documentCount: openIndices[name].primary_shards.documents.count,
        storeSize: openIndices[name].primary_shards.store_size_bytes,
      };

      return update;
    }, {});

    this.setState({ indices: indices });
  },

  timerId: undefined,
  shortTimerId: undefined,
  timerInterval: 5000,
  shortTimerInterval: 2000,

  _createArchive(indexName) {
    ArchiveActions.createArchive(indexName);
  },

  _archiveCreation() {
    if (this.state.licenseStatus.loading) {
      return <Spinner text="Loading license information" />;
    }

    if (this.state.licenseStatus.status && this.state.licenseStatus.status.valid) {
      if (!this.state.indices) {
        return <Spinner text="Loading indices" />;
      }
      return <ArchiveCreationForm indices={this.state.indices} createArchive={this._createArchive} />;
    }
    return <ArchiveLicenseStatus licenseStatus={this.state.licenseStatus} summary />;
  },

  render() {
    const systemJobs = [].concat(this.state.restoreJobs, this.state.createJobs, this.state.rebuildJobs)
      .map(job => (<SystemJob key={job.id} job={job} />));

    let progress;

    if (systemJobs.length > 0) {
      progress = (
        <Row key="system-job-progress" className="content">
          <Col md={12}>
            <h2>Job Progress</h2>
            <hr style={{ marginBottom: '5', marginTop: '10' }} />
            {systemJobs}
          </Col>
        </Row>
      );
    }

    let archiveCatalog = <Spinner text="Loading archive catalog..." />;
    if (this.state.catalog.archives) {
      archiveCatalog = (
        <ArchiveCatalog archives={this.state.catalog.archives}
                        archivesContext={this.state.catalog.archivesContext}
                        pagination={this.state.catalog.pagination}
                        diskStates={this.state.diskStates} />
      );
    }

    const configCreateRow = (
      <IfPermitted permissions={['archive:create', 'archiveconfig:read']} anyPermissions>
        <Row className="content">
          <IfPermitted permissions="archive:create">
            <Col md={6}>
              {this._archiveCreation()}
            </Col>
          </IfPermitted>
          <IfPermitted permissions="archiveconfig:read">
            <Col md={6}>
              <ArchiveConfig />
            </Col>
          </IfPermitted>
        </Row>
      </IfPermitted>
    );

    return (
      <DocumentTitle title="Archives">
        <span>
          <PageHeader title="Archives">
            <span>
              The Graylog archive feature allows you to create archives from indices. The generated archives
              are simple flat files that can be moved to cheap storage and re-imported at any time.
            </span>
            <span>
              Archive your old indices automatically by setting your index retention strategy to archive
              on the{' '} <Link to={Routes.SYSTEM.INDICES.LIST}>indices</Link>{' '}
              page.
            </span>
            <span>
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES')}>
                <Button bsStyle="info" className="active">Overview</Button>
              </LinkContainer>
              &nbsp;
              <IfPermitted permissions="archiveconfig:update">
                <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_CONFIGURATION')}>
                  <Button bsStyle="info">Configuration</Button>
                </LinkContainer>
                &nbsp;
                <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')}>
                  <Button bsStyle="info">Manage Backends</Button>
                </LinkContainer>
                &nbsp;
              </IfPermitted>
            </span>
          </PageHeader>
          {configCreateRow}
          {progress}
          <Row className="content">
            <Col md={12}>
              <div className="pull-right">
                <IfPermitted permissions="archivecatalog:rebuild">
                  <Button bsStyle="info" onClick={() => { ArchiveActions.rebuildCatalog(); }}>Rebuild Catalog</Button>
                                &nbsp;
                </IfPermitted>
                <ExternalLinkButton bsStyle="info" href={DocsHelper.toString('archiving.html')}>
                  Archive documentation
                </ExternalLinkButton>
              </div>

              {archiveCatalog}
            </Col>
          </Row>
        </span>
      </DocumentTitle>
    );
  },
});

export default ArchivePage;
