import React from 'react';
import PropTypes from 'prop-types';
import connect from 'stores/connect';

import { Spinner } from 'components/common';

import CorrelationSummary from './CorrelationSummary';
import EventsLicenseStatus from './EventsLicenseStatus';
import { EventsLicenseStore, EventsLicenseActions } from './EventsLicenseStore';

class CorrelationSummaryContainer extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    eventsLicenseStatus: PropTypes.object.isRequired,
  };

  componentDidMount() {
    EventsLicenseActions.getLicenseStatus();
  }

  render() {
    const { eventsLicenseStatus, ...otherProps } = this.props;

    if (eventsLicenseStatus.loading) {
      return <Spinner text="Loading Event Correlation information..." />;
    }

    return (
      <React.Fragment>
        <EventsLicenseStatus licenseStatus={eventsLicenseStatus} />
        <CorrelationSummary {...otherProps} />
      </React.Fragment>
    );
  }
}

export default connect(CorrelationSummaryContainer, {
  eventsLicenseStatus: EventsLicenseStore,
});
