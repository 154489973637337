import PropTypes from 'prop-types';
import React from 'react';

import { Table, Button, DropdownButton, MenuItem, OverlayTrigger, Popover } from 'components/graylog';
import { PaginatedList, SearchForm } from 'components/common';

import ArchiveCatalogTableEntry from 'archive/components/ArchiveCatalogTableEntry';
import ArchiveCatalogExportModal from 'archive/components/ArchiveCatalogExportModal';

import ArchiveActions from 'archive/ArchiveActions';

const style = require('!style/useable!css!./ArchiveCatalog.css');

class ArchivesCatalog extends React.Component {
  static propTypes = {
    archives: PropTypes.array.isRequired,
    archivesContext: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    diskStates: PropTypes.object.isRequired,
  };

  static PAGE_SIZES = [20, 50, 100, 200, 500];

  static DEFAULT_PAGE_SIZE = 20;

  componentDidMount() {
    style.use();
  }

  componentWillUnmount() {
    style.unuse();
  }

  exportModal = null;

  _onPageChange = (newPage, pageSize) => {
    if (this.props.archives) {
      ArchiveActions.searchPaginated(newPage, pageSize, this.props.pagination.query);
    }
  };

  _onSearch = (query) => {
    ArchiveActions.searchPaginated(this.props.pagination.page, this.props.pagination.per_page, query);
  };

  _onReset = () => {
    ArchiveActions.searchPaginated(this.props.pagination.page, this.props.pagination.per_page);
  };

  _onExportFilenames = () => {
    if (this.exportModal) {
      this.exportModal.open();
    }
  };

  render() {
    const archives = this.props.archives.map((archive) => {
      return (
        <ArchiveCatalogTableEntry key={`archive-entry-${archive.archive_id}`}
                                  archive={archive}
                                  context={this.props.archivesContext[archive.archive_id]}
                                  diskState={this.props.diskStates[archive.id] || { loading: true, available: false }} />
      );
    });

    const popover = (
      <Popover id="search-query-help" className="popover-wide" title="Search Syntax Help">
        <p><strong>Available search fields</strong></p>
        <Table condensed>
          <thead>
            <tr>
              <th>Field</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>index</td>
              <td>Index name of an archive</td>
            </tr>
            <tr>
              <td>id</td>
              <td>Archive ID</td>
            </tr>
            <tr>
              <td>nodes</td>
              <td>Server node names for archived messages</td>
            </tr>
            <tr>
              <td>streams</td>
              <td>Stream names for archived messages</td>
            </tr>
            <tr>
              <td>sources</td>
              <td>Source names in archived messages</td>
            </tr>
            <tr>
              <td>created</td>
              <td>Archive creation date</td>
            </tr>
            <tr>
              <td>from</td>
              <td>Minimum message timestamp in archive</td>
            </tr>
            <tr>
              <td>to</td>
              <td>Maximum message timestamp in archive</td>
            </tr>
          </tbody>
        </Table>
        <p><strong>Examples</strong></p>
        <p>
          Find archives which contain messages in a date range:<br />
          <kbd>{'from:>=2017-03-23 to:<=2017-04-01'}</kbd><br />
          <kbd>{'from:">=2016-02-18 18:10:37.055" to:"<=2016-02-18 18:13:16.368"'}</kbd>
        </p>
        <p>
          Find archives which contain messages from the <tt>errors</tt> stream:<br />
          <kbd>streams:errors</kbd>
        </p>
        <p>
          Find archives which contain messages from the <tt>errors</tt> and <tt>warnings</tt> streams:<br />
          <kbd>streams:errors streams:warnings</kbd><br />
          <kbd>streams:errors,warnings</kbd>
        </p>
        <p>
          Find archives which contain messages from the <tt>foo</tt> index and the <tt>errors</tt> stream:<br />
          <kbd>index:foo streams:errors</kbd><br />
        </p>
      </Popover>
    );

    return (

      <div className="archive-catalog-entries">
        <h2>
          Archive Catalog
          <span>&nbsp;<small>{this.props.pagination.total} total</small></span>
        </h2>
        <div>

          <PaginatedList totalItems={this.props.pagination.total}
                         pageSize={ArchivesCatalog.DEFAULT_PAGE_SIZE}
                         pageSizes={ArchivesCatalog.PAGE_SIZES}
                         onChange={this._onPageChange}>
            <SearchForm onSearch={this._onSearch} searchBsStyle="success" onReset={this._onReset} queryWidth={500} useLoadingState>
              <DropdownButton id="export-results-dropdown" title="Export Results" style={{ marginLeft: 5 }}>
                <MenuItem onClick={this._onExportFilenames}>Filenames</MenuItem>
              </DropdownButton>
              <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
                <Button bsStyle="link" className="archive-search-help-button"><i className="fa fa-fw fa-question-circle" /></Button>
              </OverlayTrigger>
            </SearchForm>
            <ArchiveCatalogExportModal ref={(c) => { this.exportModal = c; }}
                                       query={this.props.pagination.query || ''} />
            <Table condensed hover>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Created</th>
                  <th>Range</th>
                  <th>Content</th>
                  <th>Streams</th>
                  <th className="restored">Restored</th>
                </tr>
              </thead>
              {archives}
            </Table>
          </PaginatedList>
        </div>
      </div>
    );
  }
}

export default ArchivesCatalog;
