exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".oIxaxEa8zuxqSjnR7UGed{font-size:90%;height:100%;width:100%}._2b_tTGvrOWs_NjgEGVPyay{width:100%}._2b_tTGvrOWs_NjgEGVPyay .quickvalues-table{margin:0 auto;text-align:left;width:80%}._2b_tTGvrOWs_NjgEGVPyay .dc-chart{float:none}._2Ch6hy7V3AKS188YrcGF6m,.rCqdFEcPF8fl_98xdLiNZ{font-size:70px;line-height:100px;text-align:center;vertical-align:middle}._1o_qCCmVjukhPbvm6pwJWR{font-size:1.2em;position:absolute;left:20px;top:20px}@media print{.oIxaxEa8zuxqSjnR7UGed{padding:0;page-break-inside:avoid}.AnIMOXBp1TTlEb3fvLyUa{page-break-inside:avoid;page-break-after:avoid;break-after:avoid}._2b_tTGvrOWs_NjgEGVPyay{page-break-after:avoid;page-break-before:avoid;break-after:avoid;break-before:avoid;margin-bottom:4rem}.GQqibyygT2Ow7bJd8R7-V{page-break-inside:avoid;page-break-before:avoid;break-before:avoid}._1o_qCCmVjukhPbvm6pwJWR{display:none}}", ""]);

// exports
exports.locals = {
	"reportVisualization": "oIxaxEa8zuxqSjnR7UGed",
	"visualizationContainer": "_2b_tTGvrOWs_NjgEGVPyay",
	"notAvailable": "_2Ch6hy7V3AKS188YrcGF6m",
	"loading": "rCqdFEcPF8fl_98xdLiNZ",
	"draggableHandle": "_1o_qCCmVjukhPbvm6pwJWR",
	"heading": "AnIMOXBp1TTlEb3fvLyUa",
	"caption": "GQqibyygT2Ow7bJd8R7-V"
};