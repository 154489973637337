import * as Permissions from './enterprise/Permissions';

export default {
  navigation: [
    {
      description: 'Enterprise',
      children: [
        { path: '/system/archives', description: 'Archives', permissions: 'archive:read' },
        { path: '/system/auditlog', description: 'Audit Log', permissions: 'auditlog_status:read' },
        { path: '/system/licenses', description: 'Licenses', permissions: 'inputs:create' },
        { path: '/reports', description: 'Reports', permissions: 'report:update' },
      ],
    },
  ],
};
