import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { LinkContainer } from 'react-router-bootstrap';

import { EntityList, EntityListItem, PaginatedList } from 'components/common';
import { Row, Col, Button, DropdownButton, MenuItem } from 'components/graylog';
import Routes from 'routing/Routes';
import StringUtils from 'util/StringUtils';

import ArchiveBackendDeletionForm from 'archive/components/ArchiveBackendDeletionForm';

const style = require('!style/useable!css!./ArchiveBackends.css');

const ArchiveBackends = createReactClass({
  displayName: 'ArchiveBackends',

  propTypes: {
    backends: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
  },

  statics: {
    PAGE_SIZES: [20, 50, 100, 200, 500],
    DEFAULT_PAGE_SIZE: 20,
    TYPES: {
      'fs-1': 'File system',
    },
  },

  getInitialState() {
    return {};
  },

  componentDidMount() {
    style.use();
  },

  componentWillUnmount() {
    style.unuse();
  },

  deletionModals: {},

  _onDelete(backend) {
    return () => {
      if (this.deletionModals[backend.id]) {
        this.deletionModals[backend.id].open();
      }
    };
  },

  _formatBackend(backend) {
    const actions = (
      <div>
        <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS_BACKENDID')(backend.id)}>
          <Button bsStyle="info">Edit</Button>
        </LinkContainer>
        {' '}
        <DropdownButton title="More Actions" id={`backend-dropdown-${backend.id}`} pullRight>
          <MenuItem onSelect={this._onDelete(backend)}>Delete</MenuItem>
        </DropdownButton>
      </div>
    );

    const settings = Object.keys(backend.settings).map((key) => {
      let value = backend.settings[key];
      if (key === 'type') {
        value = ArchiveBackends.TYPES[backend.settings[key]];
      }
      return (
        <div key={`setting-${key}`}>
          <dt>{StringUtils.capitalizeFirstLetter(key.replace('_', ' '))}:</dt>
          <dd>{value}</dd>
        </div>
      );
    });

    const content = (
      <Col md={12}>
        <Row className="archive-backend-details">
          <Col md={12}>
            <dl>
              {settings}
            </dl>
            <ArchiveBackendDeletionForm ref={(c) => { this.deletionModals[backend.id] = c; }}
                                        backend={backend}
                                        onDelete={this.props.onDelete} />
          </Col>
        </Row>
      </Col>
    );

    return (
      <EntityListItem key={`backend-${backend.id}`}
                      title={backend.title}
                      description={backend.description}
                      actions={actions}
                      contentRow={content} />
    );
  },

  render() {
    return (
      <div className="archive-backends-list">
        <h2>
          Archive Backends
          <span>&nbsp;<small>{this.props.pagination.total} total</small></span>
        </h2>

        <p className="description archive-backends-description">
          Graylog archives are written to the configured backend. Backend configurations describe a path schema on the
          filesystem and can be managed on this page.
        </p>

        <div>
          <PaginatedList pageSize={ArchiveBackends.DEFAULT_PAGE_SIZE}
                         pageSizes={ArchiveBackends.PAGE_SIZES}
                         totalItems={this.props.pagination.total}
                         onChange={this.props.onPageChange}
                         showPageSizeSelect={false}>
            <EntityList bsNoItemsStyle="info"
                        noItemsText="There are no backends to display"
                        items={this.props.backends.map(backend => this._formatBackend(backend))} />
          </PaginatedList>
        </div>
      </div>
    );
  },
});

export default ArchiveBackends;
