import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  static propTypes = {
    onDidCatch: PropTypes.func,
    FallbackComponent: PropTypes.elementType.isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    onDidCatch: () => {},
  };

  state = {
    error: undefined,
    info: undefined,
  };

  componentDidCatch(error, info) {
    const { onDidCatch } = this.props;
    this.setState({ error: error, info: info });
    onDidCatch(error, info);
  }

  render() {
    const { error, info } = this.state;
    const { FallbackComponent, children, ...rest } = this.props;

    if (error) {
      return <FallbackComponent {...rest} error={error} info={info} />;
    }

    return React.cloneElement(children, rest);
  }
}

export default ErrorBoundary;