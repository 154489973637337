import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import uuid from 'uuid/v4';
import moment from 'moment';

import { Alert, Button, Col, ControlLabel, FormGroup, HelpBlock, Row } from 'components/graylog';
import { extractDurationAndUnit } from 'components/common/TimeUnitInput';
import { TimeUnitInput, Spinner } from 'components/common';

import CorrelationRules from './CorrelationRules';
import CorrelationPreview from './CorrelationPreview';

import commonStyles from './commonStyles.css';

export const TIME_UNITS = ['HOURS', 'MINUTES', 'SECONDS'];

const getNewRule = () => {
  return { id: uuid(), occurrence: 1, event_creator_equals: '' };
};

class CorrelationForm extends React.Component {
  static propTypes = {
    eventDefinition: PropTypes.object.isRequired,
    validation: PropTypes.object.isRequired,
    eventDefinitions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultConfig = {
    sources: [],
    rules: [getNewRule()],
    search_within_ms: 60 * 1000,
    execute_every_ms: 60 * 1000,
  };

  constructor(props) {
    super(props);

    const { execute_every_ms: executeEveryMs, search_within_ms: searchWithinMs } = props.eventDefinition.config;
    const searchWithin = extractDurationAndUnit(searchWithinMs, TIME_UNITS);
    const executeEvery = extractDurationAndUnit(executeEveryMs, TIME_UNITS);

    this.state = {
      searchWithinMsDuration: searchWithin.duration,
      searchWithinMsUnit: searchWithin.unit,
      executeEveryMsDuration: executeEvery.duration,
      executeEveryMsUnit: executeEvery.unit,
    };
  }

  propagateChange = (key, value) => {
    const { onChange } = this.props;
    onChange(key, value);
  };

  handleTimeRangeChange = (key) => {
    return (nextValue, nextUnit) => {
      const { eventDefinition } = this.props;
      const config = lodash.cloneDeep(eventDefinition.config);
      config[key] = moment.duration(lodash.max([nextValue, 1]), nextUnit).asMilliseconds();
      this.propagateChange('config', config);

      const stateFieldName = lodash.camelCase(key);
      this.setState({
        [`${stateFieldName}Duration`]: nextValue,
        [`${stateFieldName}Unit`]: nextUnit,
      });
    };
  };

  handleRuleChange = (id, nextRule) => {
    const { eventDefinition } = this.props;
    let config = lodash.cloneDeep(eventDefinition.config);
    const rule = config.rules.find(r => r.id === id);
    const ruleIdx = config.rules.indexOf(rule);
    if (rule.event_creator_equals !== nextRule.event_creator_equals) {
      config = this.updateSources(config, rule.event_creator_equals, nextRule.event_creator_equals);
    }
    config.rules[ruleIdx] = nextRule;
    this.propagateChange('config', config);
  };

  updateSources = (config, currentEventDefinitionId, nextEventDefinitionId) => {
    const nextConfig = lodash.cloneDeep(config);
    const { eventDefinitions } = this.props;
    const isEventDefinitionUsed = nextConfig.rules
      .filter(r => r.event_creator_equals === currentEventDefinitionId).length > 1;
    if (!isEventDefinitionUsed) {
      // Event definition is not used in any rule, delete it from sources
      nextConfig.sources = nextConfig.sources.filter(s => s.event_definition_id !== currentEventDefinitionId);
    }

    if (nextEventDefinitionId === undefined) {
      return nextConfig;
    }

    const isNextEventDefinitionInSources = nextConfig.sources.find(s => s.event_definition_id === nextEventDefinitionId);
    if (!isNextEventDefinitionInSources) {
      // Add source with ID to new Event Definition
      const nextEventDefinition = eventDefinitions.find(d => d.id === nextEventDefinitionId);
      const source = {
        title: nextEventDefinition.title,
        event_definition_type: nextEventDefinition.config.type,
        event_definition_id: nextEventDefinition.id,
      };
      nextConfig.sources.push(source);
    }

    return nextConfig;
  };

  handleAddEvent = () => {
    const { eventDefinition } = this.props;
    const config = lodash.cloneDeep(eventDefinition.config);
    config.rules.push(getNewRule());
    this.propagateChange('config', config);
  };

  handleRemoveEvent = (id) => {
    const { eventDefinition } = this.props;
    let config = lodash.cloneDeep(eventDefinition.config);
    const rule = config.rules.find(r => r.id === id);
    config.rules = lodash.without(config.rules, rule);
    if (rule.event_creator_equals) {
      config = this.updateSources(config, rule.event_creator_equals, undefined);
    }
    this.propagateChange('config', config);
  };

  render() {
    const { eventDefinition, eventDefinitions, validation } = this.props;
    const { searchWithinMsDuration, searchWithinMsUnit, executeEveryMsDuration, executeEveryMsUnit } = this.state;

    if (!eventDefinition.config || !eventDefinition.config.rules || eventDefinition.config.rules.length === 0) {
      return <Spinner />;
    }

    const ruleErrors = lodash.get(validation, 'errors.rules', []);
    const eventCreatorErrors = lodash.get(validation, 'errors.event_creator_equals', []);
    const errors = [...ruleErrors, ...eventCreatorErrors];

    return (
      <Row>
        <Col md={7} lg={6}>
          <h2 className={commonStyles.title}>Event Correlation Rules</h2>
          <p>
            Event Correlation Rules allow you to analyze complex sequences of Events to identify meaningful incidents.
          </p>
          {eventDefinitions.length === 0 ? (
            <Alert bsStyle="info">
              Event Correlation Rules work on top of other Events. Please start creating Filter & Aggregation Event
              Definitions first.
            </Alert>
          ) : (
            <div className={commonStyles.correlationForm}>
              <fieldset>
                <div className={`form-inline ${commonStyles.inlineFormGroup}`}>
                  <FormGroup validationState={validation.errors.search_within_ms ? 'error' : null}>
                    <ControlLabel>The following Sequence of Events must be satisfied within</ControlLabel>
                    <TimeUnitInput update={this.handleTimeRangeChange('search_within_ms')}
                                   value={searchWithinMsDuration}
                                   unit={searchWithinMsUnit}
                                   units={TIME_UNITS}
                                   clearable
                                   required />
                    {validation.errors.search_within_ms && (
                      <HelpBlock>{validation.errors.search_within_ms[0]}</HelpBlock>
                    )}
                  </FormGroup>
                </div>

                <div className={`form-inline ${commonStyles.inlineFormGroup}`}>
                  <FormGroup validationState={validation.errors.execute_every_ms ? 'error' : null}>
                    <ControlLabel>The correlation should execute every</ControlLabel>
                    <TimeUnitInput update={this.handleTimeRangeChange('execute_every_ms')}
                                   value={executeEveryMsDuration}
                                   unit={executeEveryMsUnit}
                                   units={TIME_UNITS}
                                   clearable
                                   required />
                    {validation.errors.execute_every_ms && (
                      <HelpBlock>{validation.errors.execute_every_ms[0]}</HelpBlock>
                    )}
                  </FormGroup>
                </div>
              </fieldset>

              {errors.length > 0 && (
                <Alert bsStyle="danger" className={commonStyles.validationSummary}>
                  <h4>Correlation rules with errors</h4>
                  <p>Please correct the following errors before saving this Event Definition:</p>
                  <ul>
                    {errors.map((error) => {
                      return <li key={error}>{error}</li>;
                    })}
                  </ul>
                </Alert>
              )}

              <CorrelationRules rules={eventDefinition.config.rules}
                                eventDefinitions={eventDefinitions}
                                onChange={this.handleRuleChange}
                                onRemove={this.handleRemoveEvent} />
              <Button bsStyle="success" onClick={this.handleAddEvent}>Add Event</Button>
            </div>
          )}
        </Col>
        <Col md={5} lg={5} lgOffset={1}>
          {eventDefinitions.length > 0 && (
            <CorrelationPreview rules={eventDefinition.config.rules}
                                eventDefinitions={eventDefinitions} />
          )}
        </Col>
      </Row>
    );
  }
}

export default CorrelationForm;
