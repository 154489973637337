exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".YuRWs_2aFpyQYLfOw6BGe{text-align:center;font-size:1.1em}.YuRWs_2aFpyQYLfOw6BGe h3{margin-top:5px;margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"component": "YuRWs_2aFpyQYLfOw6BGe"
};