import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from 'components/graylog';
import Routes from 'routing/Routes';

import { licenseIsLoading, isValidLicense } from '../logic/license';

class HeaderToolbar extends React.Component {
  static propTypes = {
    licenseStatus: PropTypes.object.isRequired,
    active: PropTypes.string.isRequired,
  };

  _isActive = (active, route) => active === route;

  render() {
    const { active, licenseStatus } = this.props;
    return (
      <ButtonToolbar>
        {licenseIsLoading(licenseStatus) || isValidLicense(licenseStatus) ? (
          <LinkContainer to={Routes.pluginRoute('REPORTS_NEW')} active={this._isActive(active, 'REPORTS_NEW')}>
            <Button bsStyle="success">Create report</Button>
          </LinkContainer>
        ) : (
          <OverlayTrigger overlay={<Tooltip id="missing-license">Creating new report definitions is disabled because there is no valid license.</Tooltip>}
                          placement="top">
            <Button bsStyle="success" className="disabled">Create report</Button>
          </OverlayTrigger>
        )}

        <LinkContainer to={Routes.pluginRoute('REPORTS')} active={this._isActive(active, 'REPORTS')}>
          <Button bsStyle="info">Reports</Button>
        </LinkContainer>
      </ButtonToolbar>
    );
  }
}

export default HeaderToolbar;
