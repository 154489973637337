exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2lZM-hmMmBj1J4mmK4_TvR h1,._2lZM-hmMmBj1J4mmK4_TvR h2,._2lZM-hmMmBj1J4mmK4_TvR h3{margin-top:20px;margin-bottom:10px}._2lZM-hmMmBj1J4mmK4_TvR h4,._2lZM-hmMmBj1J4mmK4_TvR h5,._2lZM-hmMmBj1J4mmK4_TvR h6{margin-top:10px;margin-bottom:10px}", ""]);

// exports
exports.locals = {
	"bootstrapHeading": "_2lZM-hmMmBj1J4mmK4_TvR"
};