import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash';

import { Col, Row } from 'components/graylog';
import { Spinner } from 'components/common';
import history from 'util/History';
import { naturalSortIgnoreCase } from 'util/SortUtils';
import Routes from 'routing/Routes';

import ReportContentsSelection from './ReportContentsSelection';
import ReportSummary from './ReportSummary';
import ReportContentsToolbar from './ReportContentsToolbar';
import ReportsActions from '../ReportsActions';
import { AvailableWidgetsActions } from '../AvailableWidgetsStore';
import ReportParameters from './ReportParameters';

class ReportContents extends React.Component {
  static propTypes = {
    report: PropTypes.object,
    reportLogo: PropTypes.string,
    action: PropTypes.oneOf(['create', 'edit']),
  };

  static defaultProps = {
    report: {
      title: '',
      subtitle: '',
      description: '',
      widgets: [],
      positions: [],
    },
    reportLogo: null,
    action: 'create',
  };

  constructor(props) {
    super(props);
    this.state = this._initState(props);
  }

  componentDidMount() {
    AvailableWidgetsActions.list().then(({ dashboards }) => {
      const { report } = this.props;
      const requiredParameters = this._extractParameters(report, dashboards);

      const sortedDashboards = dashboards.toJS()
        .sort((d1, d2) => naturalSortIgnoreCase(d1.title, d2.title));
      this.setState({ dashboards: sortedDashboards, parameters: requiredParameters });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { reportLogo } = this.props;
    if (reportLogo !== nextProps.reportLogo) {
      this.setState({ reportLogo: nextProps.reportLogo });
    }
  }

  _initState = (props) => {
    return {
      report: lodash.cloneDeep(props.report),
      reportLogo: props.reportLogo,
      dashboards: undefined,
      dataTouched: false,
      logoChanged: false,
      isSubmitting: false,
      parameters: {},
      parameterValues: props.report.parameterValues || {},
    };
  };

  _extractParameters = (report, dashboards) => {
    const widgets = report.widgets
      .map(({ dashboard_widget_id: widgetId, dashboard_id: dashboardId }) => dashboards
        .find(dashboard => dashboard.id === dashboardId)
        .widgets
        .find(widget => widget.id === widgetId));

    return widgets.flatMap(w => w.parameters)
      // TODO: Duplicate parameters need to be merged?
      .reduce((prev, cur) => ({ ...prev, [cur.name]: cur }), {});
  };

  _updateReport = (updatedReport) => {
    const { dashboards } = this.state;
    const requiredParameters = this._extractParameters(updatedReport, dashboards);
    this.setState({ report: updatedReport, dataTouched: true, parameters: requiredParameters });
  };

  _updateReportLogo = (nextReportLogo) => {
    this.setState({ reportLogo: nextReportLogo, dataTouched: true, logoChanged: true });
  };

  _confirmNavigation = (event) => {
    if (!this.state.dataTouched) {
      return;
    }

    if (!window.confirm('Do you really want to abandon this page and lose your changes? This action cannot be undone.')) {
      event.preventDefault();
    }
  };

  _saveReport = (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const callback = () => history.push(Routes.pluginRoute('REPORTS'));
    const resetState = () => this.setState({ isSubmitting: false });

    const { reportLogo, report, parameterValues } = this.state;
    const { action } = this.props;
    if (action === 'create') {
      ReportsActions.create(report, reportLogo, parameterValues).then(callback, resetState);
    } else {
      ReportsActions.update(report, reportLogo, parameterValues).then(callback, resetState);
    }
  };

  addParameterValue = (name, value) => {
    this.setState(({ parameterValues }) => ({ parameterValues: { ...parameterValues, [name]: value } }));
  };

  render() {
    const { dashboards, report, reportLogo, isSubmitting, parameters, parameterValues } = this.state;
    const { action } = this.props;
    const formId = 'report-contents-selection-form';
    const isLoading = !dashboards;

    if (isLoading) {
      return <div><Spinner text="Loading report data, please wait..." /></div>;
    }

    return (
      <form id={formId} onSubmit={this._saveReport}>
        <Row>
          <Col md={6}>
            <ReportContentsSelection formElementId={formId}
                                     report={report}
                                     reportLogo={reportLogo}
                                     dashboards={dashboards}
                                     onReportChange={this._updateReport}
                                     onReportLogoChange={this._updateReportLogo}
                                     onSaveReport={this._saveReport}
                                     onCancel={this._confirmNavigation}
                                     action={action}
                                     isLoading={isSubmitting} />
          </Col>
          <Col md={5} mdOffset={1}>
            <ReportSummary report={report} reportLogo={reportLogo}>
              <ReportParameters parameters={parameters} parameterValues={parameterValues} onChange={this.addParameterValue} />
              <ReportContentsToolbar action={action}
                                     onCancel={this._confirmNavigation}
                                     formElementId={formId}
                                     isLoading={isSubmitting} />
            </ReportSummary>
          </Col>
        </Row>
      </form>
    );
  }
}

export default ReportContents;
