import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import { Link } from 'react-router';

import { Alert, Col, Row } from 'components/graylog';
import Routes from 'routing/Routes';
import CombinedProvider from 'injection/CombinedProvider';

const { AuthenticationStore, AuthenticationActions } = CombinedProvider.get('Authentication');

const ReportTokenAuthStatus = createReactClass({
  displayName: 'ReportTokenAuthStatus',
  propTypes: {
    colWidth: PropTypes.number,
    rowClass: PropTypes.string,
  },
  mixins: [Reflux.connect(AuthenticationStore)],

  getDefaultProps() {
    return {
      colWidth: 12,
      rowClass: 'content',
    };
  },

  componentDidMount() {
    AuthenticationActions.load();
  },

  render() {
    const { authenticators } = this.state;
    const { colWidth, rowClass } = this.props;
    if (authenticators && authenticators.disabled_realms && authenticators.disabled_realms.includes('access-token')) {
      return (
        <Row className={rowClass}>
          <Col md={colWidth}>
            <Alert bsStyle="danger">
              <b>Warning!</b>&nbsp;The reporting engine requires the API tokens authentication provider.{' '}
              Please enable it in the{' '}
              <Link to={Routes.SYSTEM.AUTHENTICATION.PROVIDERS.CONFIG}>Authentication page</Link>{' '}
              before generating any reports.
            </Alert>
          </Col>
        </Row>
      );
    }

    return null;
  },
});

export default ReportTokenAuthStatus;
