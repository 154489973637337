import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import { Link } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import { Button, Row, Col } from 'components/graylog';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';

import history from 'util/History';
import Routes from 'routing/Routes';

import ArchiveBackendConfigForm from 'archive/components/ArchiveBackendConfigForm';
import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';
import ArchiveActions from 'archive/ArchiveActions';
import ArchiveStore from 'archive/ArchiveStore';

const ArchiveBackendConfigurationPage = createReactClass({
  displayName: 'ArchiveBackendConfigurationPage',

  propTypes: {
    params: PropTypes.object.isRequired,
  },

  mixins: [
    Reflux.connect(ArchiveStore),
  ],

  getInitialState() {
    return {
      backend: undefined,
    };
  },

  componentDidMount() {
    if (this.props.params.backendId) {
      ArchiveActions.getBackend(this.props.params.backendId);
    }
  },

  _checkOutputPath(path) {
    return ArchiveActions.checkOutputTemplate(path);
  },

  _updateConfiguration(backend) {
    ArchiveActions.saveBackend(backend).then(() => {
      history.push(Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS'));
    });
  },

  _isLoading() {
    return this.props.params.backendId && !this.state.backend;
  },

  render() {
    if (this._isLoading()) {
      return <Spinner />;
    }

    let { backend } = this.state;
    if (!this.props.params.backendId) {
      // TODO: Should not be hardcoded!
      backend = {
        title: '',
        description: '',
        settings: {
          type: 'fs-1',
          output_path: '',
        },
      };
    }

    return (
      <DocumentTitle title="Archive backend configuration">
        <div>
          <PageHeader title="Edit archive backend configuration">
            <span>
              The Graylog archive feature allows you to create archives from indices. The generated archives
              are simple flat files that can be moved to cheap storage and re-imported at any time.
            </span>
            <span>
              Archive your old indices automatically by setting your index retention strategy to archive
              on the{' '} <Link to={Routes.SYSTEM.INDICES.LIST}>indices</Link>{' '}
              page.
            </span>
            <span>
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES')}>
                <Button bsStyle="info">Overview</Button>
              </LinkContainer>
              &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_CONFIGURATION')}>
                <Button bsStyle="info">Configuration</Button>
              </LinkContainer>
              &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')}>
                <Button bsStyle="info" className="active">Manage Backends</Button>
              </LinkContainer>
            </span>
          </PageHeader>
          <ArchiveLicenseStatusContainer />
          <Row className="content">
            <Col lg={12}>
              <ArchiveBackendConfigForm backend={backend}
                                        checkOutputPath={this._checkOutputPath}
                                        updateConfiguration={this._updateConfiguration}
                                        cancelURL={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')} />
            </Col>
          </Row>
        </div>
      </DocumentTitle>
    );
  },
});

export default ArchiveBackendConfigurationPage;
