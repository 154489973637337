exports = module.exports = require("../../../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1BmEihxL2m400Kb1-NCxw7{margin-left:3rem;margin-right:3rem;margin-top:2rem}", ""]);

// exports
exports.locals = {
	"indentingParameters": "_1BmEihxL2m400Kb1-NCxw7"
};