import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

const LicenseViolations = createReactClass({
  propTypes: {
    version: PropTypes.number.isRequired,
    violations: PropTypes.shape({
      cluster_not_covered: PropTypes.bool,
      nodes_exceeded: PropTypes.bool,
      traffic_exceeded: PropTypes.bool,
      remote_checks_failed: PropTypes.bool,
      expired: PropTypes.bool,
      violated: PropTypes.bool,
    }).isRequired,
  },

  render() {
    const details = [];

    const { violations, version } = this.props;
    if (violations.violated) {
      details.push(<span><i className="fa fa-warning text-danger" />&nbsp;Violations detected<br /></span>);
    }
    if (violations.expired) {
      details.push(<span key="expired"><i className="fa fa-warning text-danger" />&nbsp;License has expired!<br /></span>);
    }
    if (violations.cluster_not_covered) {
      details.push(<span key="not-covered">This cluster is not covered by the license.<br /></span>);
    }

    if (version === 1) {
      if (violations.nodes_exceeded) {
        details.push(<span key="node-count">Too many nodes in cluster!<br /></span>);
      }
    } else if (version === 2) {
      if (violations.traffic_exceeded) {
        details.push(<span key="ingest">Daily traffic limit has been exceeded too many times.<br /></span>);
      }
      if (violations.remote_checks_failed) {
        details.push(<span key="remote-check">Remote checks have failed too many times.<br /></span>);
      }
    } else {
      details.push(<span key="unknown-version">Unknown license version, cannot display details.<br /></span>);
    }

    return <div>{details.length === 0 ? <span><i className="fa fa-check text-success" />&nbsp;No problems detected.</span> : details}</div>;
  },
});

export default LicenseViolations;
