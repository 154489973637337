// @flow strict
import * as React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { capitalize } from 'lodash';

import { Alert, Button, Col, Row } from 'components/graylog';
import CustomPropTypes from 'views/components/CustomPropTypes';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import type { QueryEditMode } from 'views/components/contexts/QueryEditModeContext';

import ParameterDeclarationForm from './ParameterDeclarationForm';

type Props = {
  undeclaredParameters: Immutable.Set<string>,
  onParameterSave: (ParameterMap) => mixed,
}

type DeclarationForm = {
  open: () => void,
};

type WrapperProps = {
  children: React.Node,
};

const wrapperForMode = (mode: QueryEditMode) => {
  switch (mode) {
    case 'query': return ({ children }: WrapperProps) => (
      <Row className="content">
        <Col md={12}>
          {children}
        </Col>
      </Row>
    );
    case 'widget': return ({ children }: WrapperProps) => children;
    default: throw new Error(`Invalid query edit mode: ${mode}`);
  }
};

class ParameterBar extends React.Component<Props> {
  declarationForm: ?DeclarationForm;

  static propTypes = {
    undeclaredParameters: CustomPropTypes.instanceOf(Immutable.Set).isRequired,
    onParameterSave: PropTypes.func.isRequired,
  };

  handleDeclareParameters = () => {
    if (this.declarationForm) {
      this.declarationForm.open();
    }
  };

  static contextType = QueryEditModeContext;

  render() {
    const { undeclaredParameters: undeclaredParameterNames, onParameterSave } = this.props;

    if (undeclaredParameterNames.size === 0) {
      return null;
    }

    const undeclaredParameters = Immutable.Map(undeclaredParameterNames.map((name: string) => ([
      name,
      ValueParameter.create(name, capitalize(name), '', 'string', undefined, false, ParameterBinding.empty()),
    ])));

    const Wrapper = wrapperForMode(this.context);

    return (
      <Wrapper>
        <Alert bsStyle="danger">
          <h4><strong>Undeclared query parameters:</strong> {undeclaredParameterNames.join(', ')}</h4>
          <br />
          <p>All parameters used in the search query need to be declared before the query can be used:</p>
          <br />
          <Button bsStyle="primary" bsSize="small" onClick={this.handleDeclareParameters}>Declare parameters</Button>
        </Alert>
        <ParameterDeclarationForm ref={(c: ?DeclarationForm) => { this.declarationForm = c; }}
                                  parameters={undeclaredParameters}
                                  onSave={onParameterSave} />
      </Wrapper>
    );
  }
}

export default ParameterBar;
