// @flow strict
import * as React from 'react';
import * as Immutable from 'immutable';
import { isNil } from 'lodash';

import type { ViewHook } from 'views/logic/hooks/ViewHook';
import RequiredParametersForViewForm from 'enterprise/parameters/components/RequiredParametersForViewForm';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import { SearchExecutionStateStore } from 'views/stores/SearchExecutionStateStore';

const parameterHasValue = (parameter: ValueParameter, executionState: SearchExecutionState) => (
  executionState.parameterBindings.has(parameter.name)
  && !isNil(executionState.parameterBindings.get(parameter.name).value)
  && executionState.parameterBindings.get(parameter.name).value !== ''
);

const showParameterFormIfMissingValues: ViewHook = ({ view: { search }, retry }) => {
  return new Promise((resolve, reject) => {
    const executionState: SearchExecutionState = SearchExecutionStateStore.getInitialState();
    const { parameters } = search;
    const requiresParameters = !search.requiredParameters
      .filter(p => !parameterHasValue(p, executionState))
      .isEmpty();
    if (requiresParameters) {
      const boundParameters = Immutable.Map(parameters
        .map((p: ValueParameter) => {
          const { value } = executionState.parameterBindings.get(p.name, ParameterBinding.empty());
          return value ? p.toBuilder().binding(ParameterBinding.forValue(value)).build() : p;
        })
        .map(p => [p.name, p]));

      const Component = () => <RequiredParametersForViewForm parameters={boundParameters} onSubmit={retry} />;
      return reject(Component);
    }

    return resolve(true);
  });
};

export default showParameterFormIfMissingValues;
