// @flow strict
import * as React from 'react';

import { Alert, Col, Row } from 'components/graylog';

type License = {
  missing: boolean,
  status: {
    valid: boolean,
    expired: boolean,
  }
};

const licenseStatus = ({ missing, status }: License) => {
  if (missing) {
    return 'missing';
  }
  if (status.valid) {
    return 'valid';
  }
  if (status.expired) {
    return 'expired';
  }
  return 'invalid';
};

type Props = {
  license: License;
};

const MissingEnterpriseLicense = ({ license }: Props) => (
  <Row className="content">
    <Col md={12}>
      <Alert bsStyle="danger">
        <h4>Your Graylog Enterprise license is {licenseStatus(license)}.</h4>
        To be able to use parameter support in views, you need to obtain a valid Graylog Enterprise license.
        For further information, please check the <a href="https://www.graylog.org/enterprise" rel="noopener noreferrer">Graylog Enterprise page</a>.
      </Alert>
    </Col>
  </Row>
);

MissingEnterpriseLicense.propTypes = {};

export default MissingEnterpriseLicense;
