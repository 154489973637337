exports = module.exports = require("../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media print{@page{size:A4;margin:1in}html body{margin-top:0;font-family:serif;font-size:18px}._1_2vUIRUH3B46Tln2oNCOq{width:210mm}._1ozaRL9MM25FtVw3XleTcC{padding-top:30%}._1ozaRL9MM25FtVw3XleTcC h1{font-size:2em;text-align:center;margin-bottom:.8em}._1ozaRL9MM25FtVw3XleTcC h2{color:#555!important;font-size:1.6em;text-align:center}._1_2vUIRUH3B46Tln2oNCOq h3{font-size:1.4em;font-weight:700}._1pRcnQPCp0rsvRU-Ac_RaV{white-space:pre-wrap}table._1ikze2gCJHLHYbA5XkpSno{font-size:.75em;min-width:50%;margin-bottom:30px;margin:auto}table._1ikze2gCJHLHYbA5XkpSno td,table._1ikze2gCJHLHYbA5XkpSno th{border:1px solid #ccc;padding:5px}#footer{display:none}._1kIRml00040uFp2UnkckjP{margin-top:10px;margin-bottom:30px}._1R76iNP-8M4ADLy3y3_Ysu{page-break-after:always}._3P0i3-kBkVZU35txuwP2Rt{margin-left:auto;margin-top:100px;padding:10px;text-align:center}._3P0i3-kBkVZU35txuwP2Rt img{max-height:150px;max-width:150px}}", ""]);

// exports
exports.locals = {
	"reportPage": "_1_2vUIRUH3B46Tln2oNCOq",
	"coverPage": "_1ozaRL9MM25FtVw3XleTcC",
	"description": "_1pRcnQPCp0rsvRU-Ac_RaV",
	"parameters": "_1ikze2gCJHLHYbA5XkpSno",
	"visualization": "_1kIRml00040uFp2UnkckjP",
	"pageBreak": "_1R76iNP-8M4ADLy3y3_Ysu",
	"logo": "_3P0i3-kBkVZU35txuwP2Rt"
};