// eslint-disable-next-line no-unused-vars, import/default
// We need to set the app prefix before doing anything else, so it applies to styles too.
import './webpack-entry';

import Immutable from 'immutable';

import archiveBindings from 'archive/bindings';
import auditlogBindings from 'auditlog/bindings';
import licenseBindings from 'license/bindings';
import reportBindings from 'report/bindings';
import lookupBindings from 'lookup/bindings';
import { PluginManifest, PluginStore } from 'graylog-web-plugin/plugin';
import parameterBindings from 'enterprise/parameters/ParameterBindings';
import eventCorrelationBindings from 'event-correlation/bindings';
import enterpriseMenuBindings from './EnterpriseMenuBindings';

import packageJson from '../../package.json';

const merger = (oldVal, newVal) => oldVal.concat(newVal);
const bindings = Immutable.Map().mergeWith(
  merger,
  archiveBindings,
  auditlogBindings,
  licenseBindings,
  reportBindings,
  enterpriseMenuBindings,
  parameterBindings,
  eventCorrelationBindings,
  lookupBindings,
);
const manifest = new PluginManifest(packageJson, bindings.toJS());

PluginStore.register(manifest);
