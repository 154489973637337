import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Alert, Col, Row } from 'components/graylog';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';

class ArchiveLicenseStatus extends React.Component {
  static propTypes = {
    licenseStatus: PropTypes.shape({
      status: PropTypes.object,
      missing: PropTypes.bool.isRequired,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    colWidth: PropTypes.number,
    rowClass: PropTypes.string,
    summary: PropTypes.bool,
  };

  static defaultProps = {
    colWidth: 12,
    rowClass: 'content',
    summary: false,
  };

  renderStatus = () => {
    return (
      <Alert bsStyle="danger">
        <h4 style={{ fontWeight: 'bold', marginBottom: 5 }}>Archive creation is disabled</h4>
        <p>
          Archive creation is disabled because there is no valid license.
          You can still restore existing archives, but Graylog will not create new ones.
        </p>
        <IfPermitted permissions="licenses:create">
          <p>
            See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
          </p>
        </IfPermitted>
      </Alert>
    );
  };

  render() {
    const { status, missing } = this.props.licenseStatus;

    if (!missing && (status && status.valid)) {
      return null;
    }

    const { summary, rowClass, colWidth } = this.props;

    if (summary) {
      return this.renderStatus();
    }

    return (
      <Row className={rowClass}>
        <Col md={colWidth}>
          {this.renderStatus()}
        </Col>
      </Row>
    );
  }
}

export default ArchiveLicenseStatus;
