import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import moment from 'moment';
import Store from 'logic/local-storage/Store';

import LicenseStatusStore from 'license/LicenseStatusStore';
import CombinedProvider from 'injection/CombinedProvider';

import LicenseViolationNotification from './LicenseViolationNotification';
import LicenseExpirationNotification from './LicenseExpirationNotification';
import LicenseExpirationWarningNotification from './LicenseExpirationWarningNotification';
import LicenseTrialNotification from './LicenseTrialNotification';

const { CurrentUserStore } = CombinedProvider.get('CurrentUser');

const REMINDER_STORAGE_KEY = 'license:expiration-reminder';

const LicenseNotificationContainer = createReactClass({
  displayName: 'LicenseNotificationContainer',

  // We use listenTo() instead of connect() so we can update the expirationWarningStatus when we get an updated
  // licenseStatus
  mixins: [Reflux.listenTo(LicenseStatusStore, 'onLicenseStatus'), Reflux.connect(CurrentUserStore)],

  getInitialState() {
    // We have to do this manually because Reflux.listenTo() doesn't do it automatically
    const licenseStatus = LicenseStatusStore.getInitialState();
    return {
      licenseStatus: licenseStatus,
      expirationWarningStatus: this.expirationWarningStatus(licenseStatus),
    };
  },

  onLicenseStatus(licenseStatus) {
    this.setState({
      licenseStatus: licenseStatus,
      expirationWarningStatus: this.expirationWarningStatus(licenseStatus),
    });
  },

  expirationWarningStatus(licenseStatus) {
    const newStatus = { show: true, enableReminderButton: false };

    if (licenseStatus.violated || licenseStatus.expired) {
      // Shortcut to avoid localStorage access cost when we don't display the warning anyway
      return newStatus;
    }

    const value = Store.get(REMINDER_STORAGE_KEY);
    const expirationDate = moment().add(licenseStatus.expiresIn, 'seconds');
    const almostExpired = moment().isAfter(expirationDate.subtract(5, 'days'));

    // Always show the warning if the license has almost expired. Otherwise only show it if it hasn't been dismissed.
    newStatus.show = almostExpired || !value;
    // Do not allow the user to dismiss the warning if the license has almost expired.
    newStatus.enableReminderButton = !almostExpired;

    return newStatus;
  },

  handleExpirationReminder() {
    Store.set(REMINDER_STORAGE_KEY, { createdAt: moment().toISOString() });

    this.setState({ expirationWarningStatus: this.expirationWarningStatus(this.state.licenseStatus) });
  },

  render() {
    const { licenseStatus, expirationWarningStatus, currentUser } = this.state;

    const children = [];

    if (licenseStatus.trial) {
      const duration = moment.duration(licenseStatus.trialExpiresIn, 'seconds').humanize();
      children.push(<LicenseTrialNotification currentUser={currentUser} expiresIn={duration} key={1} />);
    }

    if (licenseStatus.violated) {
      children.push(<LicenseViolationNotification currentUser={currentUser} key={2} />);
    }

    if (licenseStatus.expired) {
      const duration = moment.duration(licenseStatus.expiredSince, 'seconds').humanize();
      children.push(<LicenseExpirationNotification currentUser={currentUser} expiredSince={duration} key={3} />);
    } else if (licenseStatus.expirationUpcoming && licenseStatus.expiresIn > 0 && expirationWarningStatus.show) {
      const duration = moment.duration(licenseStatus.expiresIn, 'seconds').humanize();
      children.push(<LicenseExpirationWarningNotification currentUser={currentUser}
                                                          expiresIn={duration}
                                                          enableReminderButton={expirationWarningStatus.enableReminderButton}
                                                          onReminderClick={this.handleExpirationReminder}
                                                          key={4} />);
    }

    return (
      <div>
        {children}
      </div>
    );
  },
});

export default LicenseNotificationContainer;
