import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import { ReactGridContainer } from 'components/common';

import ReportingWidget from './ReportingWidget';
import ReportingWidgetContainer from './ReportingWidgetContainer';

import style from './ReportPreview.css';

const ROW_HEIGHT = 200 + 42; // Minimum widget height + padding + border
const MAX_HEIGHT = 2; // Do not use more than 2 rows, helping to move large widgets around

class ReportPreview extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    onPositionsChange: PropTypes.func,
    locked: PropTypes.bool,
    interactive: PropTypes.bool,
  };

  static defaultProps = {
    onPositionsChange: () => {},
    locked: false,
    interactive: true,
  };

  state = {
    widgetDimensions: {},
    renderedWidgets: [],
  };

  _calculateDimension = (value) => {
    // Return dimension if integer, but round up in other case. E.g. 0.8 columns should be 1 column, but 1 should stay 1.
    return lodash.isInteger(value) ? value : lodash.toSafeInteger(value) + 1;
  };

  _pixelsToCoordinates = (widgetDimensions) => {
    return {
      height: Math.min(this._calculateDimension(widgetDimensions.height / ROW_HEIGHT), MAX_HEIGHT),
      width: 1, // There is only one column in the grid
    };
  };

  _handleDimensionsChange = (widgetId) => {
    return (dimensions) => {
      const nextWidgetDimensions = {};
      nextWidgetDimensions[widgetId] = this._pixelsToCoordinates(dimensions);
      this.setState(prevState => ({ widgetDimensions: Object.assign({}, prevState.widgetDimensions, nextWidgetDimensions) }));
    };
  };

  _renderWidgets = (widgets) => {
    return widgets.map((widget) => {
      return (
        <div key={widget.dashboard_widget_id} className={style.widgetContainer}>
          <ReportingWidgetContainer onDimensionsChange={this._handleDimensionsChange(widget.dashboard_widget_id)}>
            <ReportingWidget dashboardId={widget.dashboard_id}
                             widget={widget}
                             width={0} // ReportWidgetContainer will take care of setting that
                             height={0} // ReportWidgetContainer will take care of setting that
                             interactive={this.props.interactive}
                             parameterValues={this.props.report.parameterValues}
                             showCaption={false} />
          </ReportingWidgetContainer>
        </div>
      );
    });
  };

  // Format layout from [{ dashboard_widget_id: 'foo', row: 1, col 1 }] to { 'foo': { row: 1, col: 1 } }, and merge
  // that with the widget sizes.
  // This method will generate an object of positions that `ReactGridContainer` can understand.
  _calculateLayout = (positions, widgetDimensions) => {
    const persistedPositions = positions || [];
    const formattedPositions = {};
    persistedPositions.forEach((position) => {
      formattedPositions[position.dashboard_widget_id] = {
        row: position.row,
        col: position.col,
      };
    });

    return lodash.merge({}, formattedPositions, widgetDimensions);
  };

  render() {
    const { report, onPositionsChange, locked, interactive } = this.props;
    const { widgetDimensions } = this.state;

    const layout = this._calculateLayout(report.positions, widgetDimensions);
    return (
      <div className={style.widgetList}>
        <ReactGridContainer positions={layout}
                            onPositionsChange={onPositionsChange}
                            rowHeight={ROW_HEIGHT}
                            isResizable={false}
                            animate={interactive}
                            locked={locked}
                            columns={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
          {this._renderWidgets(report.widgets)}
        </ReactGridContainer>
      </div>
    );
  }
}

export default ReportPreview;
