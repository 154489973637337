// @flow strict
import * as React from 'react';
// $FlowFixMe: imports from core need to be fixed in flow
import connect from 'stores/connect';

import ParameterBarWithUndeclaredParameters
  from 'enterprise/parameters/components/ParameterBarWithUndeclaredParameters';
import ParametersWithParameterBindings from 'enterprise/parameters/components/ParametersWithParameterBindings';
import { SearchExecutionStateActions } from 'views/stores/SearchExecutionStateStore';
import { SearchParameterActions } from 'enterprise/parameters/stores/SearchParameterStore';
import { SearchActions } from 'views/stores/SearchStore';
import ExecuteViewWithValue from 'enterprise/parameters/components/views/ExecuteViewWithValue';
import CreateParameterDialog from 'enterprise/parameters/creatoractions/CreateParameterDialog';
import ParameterCompletion from 'enterprise/parameters/components/searchbar/completions/ParameterCompletion';
import LicenseCheck from 'enterprise/parameters/components/LicenseCheck';
import { ViewsLicenseActions, ViewsLicenseStore } from 'enterprise/parameters/stores/ViewsLicenseStore';
import type { Completer } from 'views/components/searchbar/SearchBarAutocompletions';

import type { CompletionResult } from 'views/components/searchbar/ace-types';
import type { OverrideProps } from 'views/components/WidgetOverrideElements';
import bindParametersFromQuery from './hooks/BindParametersFromQuery';
import loadParametersFromView from './hooks/LoadParametersFromView';
import noUndeclaredOrMissingParameters from './hooks/NoUndeclaredOrMissingParameters';
import showParameterFormIfMissingValues from './hooks/ShowParameterFormIfMissingValues';
import MissingEnterpriseLicense from './components/MissingEnterpriseLicense';
import validLicensePresent from './ValidLicensePresent';
import WidgetEditModeParameterBar from './components/WidgetEditModeParameterBar';

const EnterpriseLicenseRequired = ({ children }: { children: React.Node }) => (
  <LicenseCheck errorComponent={MissingEnterpriseLicense}>
    {children}
  </LicenseCheck>
);

const ConnectedMissingEnterpriseLicense = connect(MissingEnterpriseLicense, { license: ViewsLicenseStore });

const checkLicenseOrNoop = (fn, fallback = () => Promise.resolve(true)) => (...args: any) => (validLicensePresent() ? fn(...args) : fallback(...args));

class CheckLicenseOrNoop implements Completer {
  completer: Completer;

  constructor(completer: Completer) {
    this.completer = completer;
  }

  getCompletions(...args: any): Array<CompletionResult> {
    return validLicensePresent() ? this.completer.getCompletions(...args) : [];
  }
}

export default {
  'views.elements.header': [() => (
    <EnterpriseLicenseRequired>
      <ParametersWithParameterBindings onChange={SearchExecutionStateActions.bindParameterValue}
                                       onExecute={SearchActions.refresh} />
    </EnterpriseLicenseRequired>
  )],
  'views.elements.queryBar': [() => (validLicensePresent()
    ? (
      <ParameterBarWithUndeclaredParameters onParameterSave={SearchParameterActions.declare} />
    )
    : null),
  ],
  'views.overrides.widgetEdit': [({ override }: OverrideProps) => (validLicensePresent()
    ? <WidgetEditModeParameterBar override={override} />
    : null)],
  'views.hooks.executingView': [
    checkLicenseOrNoop(showParameterFormIfMissingValues),
  ],
  'views.hooks.loadingView': [
    () => ViewsLicenseActions.status(),
    checkLicenseOrNoop(
      () => Promise.resolve(true),
      ({ view }) => {
        const usesParameters = !view.search.parameters.isEmpty();
        return usesParameters ? Promise.reject<React.Node>(ConnectedMissingEnterpriseLicense) : Promise.resolve(true);
      },
    ),
    checkLicenseOrNoop(loadParametersFromView),
    checkLicenseOrNoop(bindParametersFromQuery),
  ],
  'views.hooks.searchRefresh': [
    checkLicenseOrNoop(noUndeclaredOrMissingParameters, () => true),
  ],
  'views.completers': [
    new CheckLicenseOrNoop(new ParameterCompletion()),
  ],
  'views.requires.provided': [
    'parameters',
  ],
  valueActions: [{
    type: 'execute-view-with-value',
    title: 'Insert into view',
    component: ExecuteViewWithValue,
    condition: validLicensePresent,
  }],
  creators: [{
    type: 'generic',
    title: 'Parameter',
    component: CreateParameterDialog,
    condition: validLicensePresent,
  }],
};
