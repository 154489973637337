// @flow strict
import * as Immutable from 'immutable';
import { get } from 'lodash';

import type { SearchRefreshCondition } from 'views/logic/hooks/SearchRefreshCondition';
import { hasMissingSearchParameters, SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';

const hasUndeclaredParameters = (metadata) => {
  const undeclared = get(metadata, 'undeclared', Immutable.Set());
  return !undeclared.isEmpty();
};

const noUndeclaredOrMissingParameters: SearchRefreshCondition = ({ searchMetadata, executionState }) => (
  !hasUndeclaredParameters(searchMetadata) && !hasMissingSearchParameters(SearchParameterStore.getInitialState(), executionState)
);

export default noUndeclaredOrMissingParameters;
