import Reflux from 'reflux';
import URI from 'urijs';
import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import StoreProvider from 'injection/StoreProvider';

import Widget from 'views/logic/widgets/Widget';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import ReportsActions from './ReportsActions';

const SessionStore = StoreProvider.getStore('Session');

const _deserializeResponse = ({ widgets, parameter_values: parameterValues, ...rest }) => ({
  ...rest,
  widgets: widgets.map(widget => ({ ...widget, config: Widget.fromJSON(widget).config })),
  parameterValues: Object.entries(parameterValues)
    .map(([name, { value }]) => [name, value])
    .reduce((prev, [name, value]) => ({ ...prev, [name]: value }), {}),
});

const ReportsStore = Reflux.createStore({
  listenables: [ReportsActions],
  sourceUrl: '/plugins/org.graylog.plugins.report',
  reports: undefined,

  getInitialState() {
    return {
      reports: this.reports,
    };
  },

  getDownloadURL(report) {
    const url = new URI(this._reportingUrl(`${report.id}/generate/`));

    if (URLUtils.areCredentialsInURLSupported()) {
      url.username(SessionStore.getSessionId()).password('session');
    }

    return url.toString();
  },

  _reportingUrl(path) {
    const effectivePath = path ? `${this.sourceUrl}/reports/${path}` : `${this.sourceUrl}/reports`;
    return URLUtils.qualifyUrl(effectivePath);
  },

  _errorHandler(error) {
    let errorMessage;
    try {
      errorMessage = error.additional.body.message;
    } catch (e) {
      errorMessage = error.message;
    }
    return errorMessage;
  },

  list() {
    const promise = fetch('GET', this._reportingUrl());
    promise.then(
      (response) => {
        this.reports = response.reports;
        this.trigger({ reports: response.reports });

        return this.reports;
      },
      (error) => {
        UserNotification.error(`Fetching reports failed with status: ${error}`, 'Could not retrieve reports');
      },
    );
    ReportsActions.list.promise(promise);
  },

  _adaptReportToRequest(report, reportLogo, parameterValues) {
    const { id, parameterValues: _, positions = [], widgets, parameters, ...rest } = report;
    // We omit `parameters` on purpose, since they are injected from the server when serving the response.
    return {
      logo: reportLogo,
      widgets: widgets.map(widget => widget.dashboard_widget_id),
      parameter_values: this._adaptParameterValuesToBindings(parameterValues),
      positions,
      ...rest,
    };
  },

  _adaptParameterValuesToBindings(parameterValues) {
    return Object.entries(parameterValues)
      .map(([name, value]) => [name, ParameterBinding.create('value', value)])
      .reduce((prev, [name, binding]) => ({ ...prev, [name]: binding }), {});
  },

  create(report, reportLogo, parameterValues) {
    const promise = fetch('POST', this._reportingUrl(), this._adaptReportToRequest(report, reportLogo, parameterValues));
    promise.then(
      (response) => {
        UserNotification.success('Report created successfully', `Report "${response.title}" was created successfully.`);
        this.list();
        return response;
      },
      (error) => {
        UserNotification.error(`Creating report "${report.title}" failed with status: ${error}`,
          'Could not save report');
      },
    );
    ReportsActions.create.promise(promise);
  },

  get(reportId) {
    const promise = fetch('GET', this._reportingUrl(reportId));
    promise.then(
      (response) => {
        const mappedResponse = _deserializeResponse(response);
        this.trigger({ report: mappedResponse });
        return mappedResponse;
      },
      (error) => {
        UserNotification.error(`Fetching report ${reportId} failed with status: ${error}`, 'Could not retrieve report');
      },
    );
    ReportsActions.get.promise(promise);
  },

  getReportLogo(reportId) {
    const promise = fetch('GET', this._reportingUrl(`${reportId}/logo`));
    promise.then(
      (response) => {
        this.trigger({ reportLogo: response.logo });
        return response;
      },
      (error) => {
        UserNotification.error(`Fetching report logo ${reportId} failed with status: ${error}`, 'Could not retrieve report logo');
      },
    );
    ReportsActions.getReportLogo.promise(promise);
  },

  update(updatedReport, updatedReportLogo, updatedParameterValues) {
    const promise = fetch('PUT', this._reportingUrl(updatedReport.id), this._adaptReportToRequest(updatedReport, updatedReportLogo, updatedParameterValues));
    promise.then(
      (response) => {
        UserNotification.success(`Report "${response.title}" was updated successfully.`, 'Report updated successfully');
        this.list();
        return response;
      },
      (error) => {
        UserNotification.error(`Updating Report "${updatedReport.title}" contents failed with status: ${error}.`,
          'Report contents could not be updated');
      },
    );
    ReportsActions.update.promise(promise);
  },

  updateScheduling(reportId, updatedScheduling) {
    const promise = fetch('PUT', this._reportingUrl(`${reportId}/scheduling`), {
      scheduling: updatedScheduling,
    });
    promise.then(
      (response) => {
        UserNotification.success(`Report "${response.title}" scheduling was updated successfully.`,
          'Report scheduling updated successfully');
        return response;
      },
      (error) => {
        UserNotification.error(`Updating Report ${reportId} scheduling failed with status: ${error}.`,
          'Report scheduling could not be updated');
      },
    );
    ReportsActions.updateScheduling.promise(promise);
  },

  updatePositions(reportId, updatedPositions) {
    const promise = fetch('PUT', this._reportingUrl(`${reportId}/positions`), {
      positions: updatedPositions,
    });
    promise.then(
      (response) => {
        UserNotification.success(undefined, 'Report layout updated successfully');
        const mappedResponse = _deserializeResponse(response);
        this.trigger({ report: mappedResponse });
        return mappedResponse;
      },
      (error) => {
        UserNotification.error(`Updating Report ${reportId} layout failed with status: ${error}.`,
          'Report layout could not be updated');
      },
    );
    ReportsActions.updatePositions.promise(promise);
  },

  delete(report) {
    const promise = fetch('DELETE', this._reportingUrl(`${report.id}`));
    promise.then(
      (response) => {
        UserNotification.success(`Report "${report.title}" was deleted successfully.`, 'Report deleted successfully');
        this.list();
        return response;
      },
      (error) => {
        UserNotification.error(`Deleting Report "${report.title}" failed with status: ${error}.`,
          'Report could not be deleted');
      },
    );
    ReportsActions.delete.promise(promise);
  },

  sendEmail(report) {
    const promise = fetch('GET', this._reportingUrl(`${report.id}/email/`));
    promise.then(
      (response) => {
        UserNotification.success('The Report is being generated and will be send to all recipients shortly, check your inbox in a few minutes.',
          'Report delivery scheduled');
        return response;
      },
      () => {
        UserNotification.error(`Sending Report "${report.title}" email failed. Please check your server logs for more information.`,
          'Could not send Report');
      },
    );
    ReportsActions.sendEmail.promise(promise);
  },

  getHistory(report, skip, limit) {
    const url = new URI(this._reportingUrl(`${report.id}/history/`));
    const queryParams = {
      skip: skip,
      limit: limit,
    };
    url.query(queryParams);
    const promise = fetch('GET', url.toString());
    promise.then(
      (response) => {
        this.trigger({ history: response.history, totalHistoryItems: response.total });
        return response;
      },
      (error) => {
        UserNotification.error(`Getting Report "${report.title}" history failed with status: ${error}.`,
          'Could not get Report history');
      },
    );
    ReportsActions.getHistory.promise(promise);
  },

});

export default ReportsStore;
