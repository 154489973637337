import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

import { Button, ButtonToolbar } from 'components/graylog';
import Routes from 'routing/Routes';

class ReportHeaderToolbar extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    active: PropTypes.string.isRequired,
  };

  _isActive = (active, route) => {
    return active === route;
  };

  render() {
    const { report, active } = this.props;

    return (
      <ButtonToolbar>
        <LinkContainer to={Routes.pluginRoute('REPORTS')}>
          <Button bsStyle="info">Reports</Button>
        </LinkContainer>
        <LinkContainer to={Routes.pluginRoute('REPORTS_REPORTID_CONTENTS')(report.id)}>
          <Button bsStyle="info" className={this._isActive(active, 'REPORTS_REPORTID_CONTENTS')}>Modify content</Button>
        </LinkContainer>
        <LinkContainer to={Routes.pluginRoute('REPORTS_REPORTID_CONFIGURATION')(report.id)}>
          <Button bsStyle="info" className={this._isActive(active, 'REPORTS_REPORTID_CONFIGURATION')}>Edit configuration</Button>
        </LinkContainer>
        <LinkContainer to={Routes.pluginRoute('REPORTS_REPORTID_HISTORY')(report.id)}>
          <Button bsStyle="info" className={this._isActive(active, 'REPORTS_REPORTID_HISTORY')}>History</Button>
        </LinkContainer>
      </ButtonToolbar>
    );
  }
}

export default ReportHeaderToolbar;
