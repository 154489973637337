import Reflux from 'reflux';

import ArchiveActions from 'archive/ArchiveActions';
import ArchiveCatalog from 'archive/components/ArchiveCatalog';
import ArchiveBackends from 'archive/components/ArchiveBackends';

import UserNotification from 'util/UserNotification';
import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

const urlPrefix = '/plugins/org.graylog.plugins.archive';

const ArchiveStore = Reflux.createStore({
  listenables: [ArchiveActions],
  diskStates: {},

  init() {
    this.pagination = this.getInitialState().catalog.pagination;
    this.backendPagination = this.getInitialState().backends.pagination;
  },

  getInitialState() {
    return {
      config: undefined,
      catalog: {
        archives: undefined,
        archivesContext: {},
        pagination: {
          page: 1,
          per_page: ArchiveCatalog.DEFAULT_PAGE_SIZE,
          query: '',
        },
      },
      diskStates: {},
      backends: {
        backends: [],
        pagination: {
          page: 1,
          per_page: ArchiveBackends.DEFAULT_PAGE_SIZE,
          total: 0,
          query: '',
        },
      },
      filenamesExport: undefined,
    };
  },

  _errorHandler(message, title, cb) {
    return (error) => {
      let errorMessage;
      try {
        errorMessage = error.additional.body.message;
      } catch (e) {
        errorMessage = error.message;
      }
      UserNotification.error(`${message}: ${errorMessage}`, title);
      if (cb) {
        cb(error);
      }
    };
  },

  _url(path) {
    return URLUtils.qualifyUrl(`${urlPrefix}${path}`);
  },

  config() {
    const promise = fetch('GET', this._url('/config'));

    promise.then((response) => {
      this.trigger({ config: response });
    }, this._errorHandler('Fetching config failed', 'Could not retrieve archive config'));

    ArchiveActions.config.promise(promise);
  },

  saveConfig(config) {
    const promise = fetch('PUT', this._url('/config'), config);

    promise.then((response) => {
      this.trigger({ config: response });
      UserNotification.success('Archive configuration was updated successfully');
    }, this._errorHandler('Updating archive config failed', 'Unable to update archive config'));

    ArchiveActions.saveConfig.promise(promise);
  },

  searchPaginated(page, perPage, query) {
    let url;
    if (query) {
      url = this._url(`/archives/catalog?page=${page}&per_page=${perPage}&query=${encodeURIComponent(query)}`);
    } else {
      url = this._url(`/archives/catalog?page=${page}&per_page=${perPage}`);
    }
    const promise = fetch('GET', url);

    promise.then((response) => {
      const pagination = {
        count: response.count,
        total: response.total,
        page: response.page,
        per_page: response.per_page,
        query: response.query,
      };
      this.pagination = pagination;
      this.trigger({ catalog: { archives: response.archives, archivesContext: response.archives_context, pagination: pagination } });
    }, this._errorHandler('Fetching archive catalog failed', 'Could not retrieve the archive catalog'));

    ArchiveActions.searchPaginated.promise(promise);
  },

  createArchive(indexName) {
    const promise = fetch('POST', this._url(`/archives/${indexName}`));

    promise.then(() => {
      UserNotification.success(`Archive job for index ${indexName} started`);
    }, this._errorHandler('Archive creation failed', `Could not create archive for index: ${indexName}`));

    ArchiveActions.createArchive.promise(promise);
  },

  restoreArchive(backendId, archiveId) {
    const promise = fetch('POST', this._url(`/archives/backend/${backendId}/${archiveId}/restore`));

    promise.then(() => {
      UserNotification.success(`Archive restore job for archive ${archiveId} started`);
    }, this._errorHandler('Index restore failed', `Could not restore archive: ${archiveId}`));

    ArchiveActions.restoreArchive.promise(promise);
  },

  deleteArchive(backendId, archiveId) {
    const promise = fetch('DELETE', this._url(`/archives/backend/${backendId}/${archiveId}`));

    promise.then(() => {
      UserNotification.success(`Archive ${archiveId} deleted`);
      const { pagination } = this;
      this.searchPaginated(pagination.page, pagination.per_page, pagination.query);
    }, this._errorHandler('Archive deletion failed', `Could not delete archive: ${archiveId}`));

    ArchiveActions.deleteArchive.promise(promise);
  },

  availability(entryId) {
    const promise = fetch('GET', this._url(`/archives/catalog/${entryId}/available`));
    this.diskStates[entryId] = { loading: true };
    this.trigger({ diskStates: this.diskStates });

    promise.then((response) => {
      this.diskStates[entryId] = { available: response.available, loading: false };
      this.trigger({ diskStates: this.diskStates });
    }, this._errorHandler('Availability check failed', `Could not check disk status for archive: ${entryId}`));

    ArchiveActions.availability.promise(promise);
  },

  rebuildCatalog() {
    const promise = fetch('POST', this._url('/archives/catalog/rebuild'));

    promise.then();
    ArchiveActions.rebuildCatalog.promise(promise);
  },

  listBackends(page, perPage) {
    const url = this._url(`/backends?page=${page}&per_page=${perPage}`);
    const promise = fetch('GET', url);

    promise.then((response) => {
      const pagination = {
        count: response.count,
        total: response.total,
        page: response.page,
        per_page: response.per_page,
        query: response.query,
      };
      this.backendPagination = pagination;
      this.trigger({ backends: { backends: response.backends, pagination: pagination } });
    }, this._errorHandler('Fetching archive backends failed', 'Could not retrieve archive backends'));

    ArchiveActions.listBackends.promise(promise);
  },

  deleteBackend(backend, deleteArchives) {
    const promise = fetch('DELETE', this._url(`/backends/${backend.id}?delete_archives=${deleteArchives}`));

    promise.then(() => {
      UserNotification.success(`Backend "${backend.title}" deleted`);
      this.listBackends(this.backendPagination.page, this.backendPagination.per_page);
    }, this._errorHandler('Backend deletion failed', `Could not delete backend: ${backend.title}`));

    ArchiveActions.deleteBackend.promise(promise);
  },

  getBackend(backendId) {
    const url = this._url(`/backends/${backendId}`);
    const promise = fetch('GET', url);

    promise.then((response) => {
      this.trigger({ backend: response });
    }, this._errorHandler('Fetching archive backend failed', 'Could not retrieve archive backend'));

    ArchiveActions.getBackend.promise(promise);
  },

  saveBackend(backend) {
    let promise;
    if (backend.id) {
      promise = fetch('PUT', this._url(`/backends/${backend.id}`), backend);
    } else {
      promise = fetch('POST', this._url('/backends'), backend);
    }

    promise.then((response) => {
      this.trigger({ backend: response });
      UserNotification.success('Archive backend was saved successfully');
    }, this._errorHandler('Saving archive backend failed', 'Unable to save archive backend'));

    ArchiveActions.saveBackend.promise(promise);
  },

  checkOutputTemplate(template) {
    const url = this._url(`/backends/test-output-template?template=${template}`);
    const promise = fetch('GET', url);

    ArchiveActions.checkOutputTemplate.promise(promise);
  },

  exportFilenames(query) {
    let url = this._url('/archives/catalog/export/filename');
    if (query) {
      url = `${url}?query=${encodeURIComponent(query)}`;
    }
    const promise = fetch('GET', url);

    promise.then((response) => {
      this.trigger({ filenameExport: response.filenames });
    }, this._errorHandler('Exporting catalog filenames failed', 'Could not export the archive catalog filenames'));

    ArchiveActions.exportFilenames.promise(promise);
  },
});

export default ArchiveStore;
