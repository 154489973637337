import React from 'react';
import PropTypes from 'prop-types';

import { extractDurationAndUnit } from 'components/common/TimeUnitInput';
import { Pluralize } from 'components/common';

import { TIME_UNITS } from './CorrelationForm';

class CorrelationSummary extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
  };

  renderEvent = (source, rule) => {
    const notForPeriod = extractDurationAndUnit(rule.not_for_period, TIME_UNITS);

    let details;
    switch (rule.state_logic) {
      case 'not_and':
        details = <dd>Should not occur, and it is followed by another Event</dd>;
        break;
      case 'not_for':
        details = <dd>Should not occur in the next {notForPeriod.duration} {notForPeriod.unit.toLowerCase()}</dd>;
        break;
      default:
        details = (
          <dd>Should occur {rule.occurrence} <Pluralize singular="time" plural="times" value={rule.occurrence} /></dd>
        );
    }
    return (
      <dl>
        <dt><em>{source.title || 'No Event assigned'}</em></dt>
        {details}
      </dl>
    );
  };

  renderEvents = (sources, rules) => {
    return rules.map((rule, idx) => {
      const source = sources.find(s => s.event_definition_id === rule.event_creator_equals) || {};
      return (
        <React.Fragment key={rule.id}>
          <dt>Event #{idx + 1}</dt>
          {this.renderEvent(source, rule)}
        </React.Fragment>
      );
    });
  };

  render() {
    const { config } = this.props;
    const {
      sources,
      rules,
      search_within_ms: searchWithinMs,
      execute_every_ms: executeEveryMs,
    } = config;

    const searchWithin = extractDurationAndUnit(searchWithinMs, TIME_UNITS);
    const executeEvery = extractDurationAndUnit(executeEveryMs, TIME_UNITS);

    return (
      <dl>
        <dt>Correlate Events within</dt>
        <dd>{searchWithin.duration} {searchWithin.unit.toLowerCase()}</dd>
        <dt>Execute Correlation every</dt>
        <dd>{executeEvery.duration} {executeEvery.unit.toLowerCase()}</dd>
        {this.renderEvents(sources, rules)}
      </dl>
    );
  }
}

export default CorrelationSummary;
