import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';

import { Col, Row } from 'components/graylog';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';

import ReportHeaderToolbar from '../common/ReportHeaderToolbar';
import ReportValidations from '../common/ReportValidations';
import ReportContents from './ReportContents';
import ReportsActions from '../ReportsActions';
import ReportsStore from '../ReportsStore';

const EditReportContentsPage = createReactClass({
  displayName: 'EditReportContentsPage',

  propTypes: {
    params: PropTypes.object.isRequired,
  },

  mixins: [Reflux.connect(ReportsStore)],

  componentDidMount() {
    ReportsActions.get(this.props.params.reportId);
    ReportsActions.getReportLogo(this.props.params.reportId);
  },

  render() {
    const { report, reportLogo } = this.state;
    const isLoading = !report;
    if (isLoading) {
      return <Spinner text="Loading report data, please wait..." />;
    }

    return (
      <DocumentTitle title={`Edit report ${report.title}`}>
        <div>
          <PageHeader title={`Edit report ${report.title}`}>
            <span>
              Decide which content should be part of the report while you see its preview. You can schedule and
              configure the report in the next screen.
            </span>
            {null}
            <ReportHeaderToolbar report={report} active="REPORTS_REPORTID_CONTENTS" />
          </PageHeader>

          <ReportValidations />

          <Row className="content">
            <Col md={12}>
              <ReportContents key={report.id} report={report} reportLogo={reportLogo} action="edit" />
            </Col>
          </Row>
        </div>
      </DocumentTitle>
    );
  },
});

export default EditReportContentsPage;
