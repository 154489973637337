import { PluginManifest, PluginStore } from 'graylog-web-plugin/plugin';

import MongoDBDataAdapterFieldSet from './components/MongoDBDataAdapterFieldSet';
import MongoDBDataAdapterSummary from './components/MongoDBDataAdapterSummary';
import MongoDBDataAdapterDocumentation from './components/MongoDBDataAdapterDocumentation';

PluginStore.register(new PluginManifest({}, {
  lookupTableAdapters: [
    {
      type: 'mongodb',
      displayName: 'MongoDB',
      formComponent: MongoDBDataAdapterFieldSet,
      summaryComponent: MongoDBDataAdapterSummary,
      documentationComponent: MongoDBDataAdapterDocumentation,
    },
  ],
}));
