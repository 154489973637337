// @flow strict
import * as Immutable from 'immutable';

type InternalState = {
  id: string,
  dataAdapterId: string,
  key: string,
  values: Array<string>,
};

export type MongoDBDataAdapterEntryJSON = {
  id: string,
  data_adapter_id: string,
  key: string,
  values: Array<string>,
};

export default class MongoDBDataAdapterEntry {
  _value: InternalState;

  // eslint-disable-next-line no-undef
  constructor(id: string, dataAdapterId: string, key: string, values: Array<string>) {
    this._value = { id, dataAdapterId, key, values };
  }

  get id() {
    return this._value.id;
  }

  get dataAdapterId() {
    return this._value.dataAdapterId;
  }

  get key() {
    return this._value.key;
  }

  get values() {
    return this._value.values;
  }

  toBuilder() {
    // eslint-disable-next-line no-use-before-define
    return new Builder(Immutable.Map(this._value));
  }

  // eslint-disable-next-line no-undef
  static create(id: string, dataAdapterId: string, key: string, value: Array<string>) {
    return new MongoDBDataAdapterEntry(id, dataAdapterId, key, value);
  }

  static builder() {
    // eslint-disable-next-line no-use-before-define
    return new Builder();
  }

  toJSON() {
    const { id, dataAdapterId, key, values } = this._value;

    return {
      id,
      data_adapter_id: dataAdapterId,
      key,
      values,
    };
  }

  static fromJSON(_value: MongoDBDataAdapterEntryJSON) {
    // eslint-disable-next-line camelcase
    const { id, data_adapter_id, key, values } = _value;
    return MongoDBDataAdapterEntry.create(id, data_adapter_id, key, values);
  }
}

type BuilderState = Immutable.Map<string, any>;

class Builder {
  _value: BuilderState;

  constructor(value: BuilderState = Immutable.Map()) {
    this._value = value;
  }

  // eslint-disable-next-line no-undef
  id(value: $PropertyType<InternalState, 'id'>) {
    return new Builder(this._value.set('id', value));
  }

  // eslint-disable-next-line no-undef
  dataAdapterId(value: $PropertyType<InternalState, 'dataAdapterId'>) {
    return new Builder(this._value.set('dataAdapterId', value));
  }

  // eslint-disable-next-line no-undef
  key(value: $PropertyType<InternalState, 'key'>) {
    return new Builder(this._value.set('key', value));
  }

  // eslint-disable-next-line no-undef
  values(values: $PropertyType<InternalState, 'values'>) {
    return new Builder(this._value.set('values', values));
  }

  build() {
    const { id, dataAdapterId, key, values } = this._value.toObject();
    return new MongoDBDataAdapterEntry(id, dataAdapterId, key, values);
  }
}
