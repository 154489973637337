// @flow strict
import { SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import Parameter from 'views/logic/parameters/Parameter';
import type { Completer } from 'views/components/searchbar/SearchBarAutocompletions';
import type { CompletionResult, Token } from 'views/components/searchbar/ace-types';

const _parameterResult = (parameter: Parameter, score: number = 1): CompletionResult => {
  const name = `$${parameter.name}$`;
  const description = parameter.description.length > 0 ? ` - ${parameter.description}` : '';
  return {
    name,
    value: name,
    score,
    meta: `parameter${description}`,
  };
};

const _wrapParameter = name => `$${name}$`;

class ParameterCompletion implements Completer {
  parameters: ParameterMap;

  constructor() {
    this.parameters = SearchParameterStore.getInitialState().mapKeys(_wrapParameter);
    SearchParameterStore.listen((newParameters) => { this.parameters = newParameters.mapKeys(_wrapParameter); });
  }

  getCompletions = (currentToken: ?Token, lastToken: ?Token, prefix: string) => {
    return this.parameters
      .filter((parameter, name) => name.indexOf(prefix) >= 0)
      .map(_parameterResult)
      .toArray();
  }
}

export default ParameterCompletion;
